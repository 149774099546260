.homelogo {
  max-width: 10rem;
  display: flex;
  margin: 0 auto;
  margin-top: 0;
  padding-bottom: 2rem;
  position: relative;
  z-index: 11;

  @media (max-width: 768px) {
    margin-top: -4rem;
  }
}

.under-logo-screen {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #0c102b;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 1;
  transition: opacity .5s;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.noscroll {
  overflow: hidden;
}

#navbar-collapse {
  @media (max-width: 768px) {
    background-color: $dark;
    border-top: 4px solid white;
    padding: 1rem;
  }
}